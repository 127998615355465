
import { Component, OnInit , } from '@angular/core';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'message',
  templateUrl: './message-modal.component.html',
  //templateUrl: './message-modal.component.html',
  
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  constructor(
    public messageService: MessageService,
   
  ) { }

  ngOnInit() {
 
  }
  close() {
   
}

}
