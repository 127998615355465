
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import {ToolsService} from '../../services/tools.service';

@Component({
  selector: 'app-backend-header',
  templateUrl: './backend-header.component.html',
  styleUrls: ['./backend-header.component.css']
})
export class BackendHeaderComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  is_admin: number = 0;
  username: string = '';
  userId: number = null;
  // tslint:disable-next-line: variable-name
  is_loading: boolean = false;
  constructor(
    private authService: AuthService,
    private toolsService: ToolsService,
    private router: Router
  ) { }

  ngOnInit() {
    const currentuser =  this.authService.getDecodeToken(localStorage.getItem('currentUser'));
    this.is_admin = currentuser.is_admin;
    this.username = currentuser.username;
    this.userId = currentuser.sub;
    console.log(currentuser);
  }

  logout() {
    this.authService.logout();
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
  importDatabase(event) {
    console.log(event);
    if (confirm('Are you sure to import new database this shoud  take a long time')) {
      this.is_loading = true;
      this.toolsService.getDatabase().subscribe(
      result => {
        this.is_loading = false;
        if (!result.errors){
          alert('Import new database Done');
          this.router.navigateByUrl('/backend/client');
        }else {
          alert(result.errors);
        }

      }
    );
    }
  }
}
