import { Constants } from './constants';
import { Pipe, PipeTransform } from '@angular/core';
//import { DatePipe } from '@angular/common';

@Pipe({
  name: 'phoneFormat'
})

export class PhoneFormatPipe  implements PipeTransform {
  transform(value: any, args?: any): any {
 
    let returned= value;
    if(value !== null) {
   
      switch (value.length) {
      case 9:
        returned = '+33'+value;  
       
        break;
      case 11:
        
        returned = value.substr(0, 2) == '33'? value.replace('33','+33'):value; 
        returned =returned = '+'+value;
        break; 
    }
  }
    return returned;
  }
}
