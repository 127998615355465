
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BackendHeaderComponent } from './backend-header/backend-header.component';
import { BackendFooterComponent } from './backend-footer/backend-footer.component';
import { MessageComponent } from './message/message.component';
//import { MaterialNavComponent } from './material-nav/material-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AngularMaterialModule } from '../angular-material.module';
export {DateFormatPipe,DateTimeFormatPipe,TimeStampFormatPipe,MonthToDatePipe} from '../utils/datepipe'
import {DateFormatPipe,DateTimeFormatPipe,TimeStampFormatPipe,MonthToDatePipe} from '../utils/datepipe'
import {PhoneFormatPipe} from '../utils/phonepipe'
import {BrReplacePipe} from '../utils/brpipe'
import {CurrencyPipe} from '../utils/currencypipe'
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BackendHeaderComponent,
    BackendFooterComponent,
    MessageComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
    PhoneFormatPipe,
    TimeStampFormatPipe,
    BrReplacePipe,
    CurrencyPipe,
    MonthToDatePipe
   // MaterialNavComponent,
    
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BackendHeaderComponent,
    BackendFooterComponent,
    MessageComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
    PhoneFormatPipe,
    TimeStampFormatPipe,
    BrReplacePipe,
    CurrencyPipe,
    MonthToDatePipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    AngularMaterialModule,
   /* DateFormatPipe,
    DateTimeFormatPipe,
    PhoneFormatPipe,
    TimeStampFormatPipe,
    BrReplacePipe,*/
    
  ],
  providers: [
    DateFormatPipe,
    DateTimeFormatPipe,
    PhoneFormatPipe,
    TimeStampFormatPipe,
    BrReplacePipe,
    CurrencyPipe
  ]

})
export class SharedModule { }
