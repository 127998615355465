import { Constants } from './constants';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
let months = {
  'janvier' : '01',
  'février' : '02',
  'fevrier' : '02',
  'mars' : '03',
  'avril' : '04',
  'mai' : '05',
  'juin' : '06',
  'juillet' : '07',
  'aout' : '08',
  'septembre' : '09',
  'octobre' : '10',
  'novenbre' : '11',
  'decenbre' : '12',
  'décenbre' : '12'
}
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value === null) return
    try {
      return super.transform(value, Constants.DATE_FMT);
    } catch(err) {
      if (value.indexOf("/") === -1) {
        let arr= value.split(" ")
        if (arr.length>1) {
          if (months[arr[0].toLowerCase()] != undefined) {
            return "01/" + months[arr[0]] + "/" + arr[1]
          }
        }
      } else {
      return value
      }
    }
    
  }
}
@Pipe({
  name: 'monthToDate'
})
export class MonthToDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value === null|| value.lenth == 0) return
     try {
      if (value.indexOf("/") === -1) {
        let arr= value.split(" ")
        if (arr.length>1) {
          return value 

          }
        } else {

        }
      
    } catch(err) {
      return value
    }
    
  }
}
@Pipe({
    name: 'dateTimeFormat'
  })
  export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      if(value === null) return
    
      return super.transform(value, Constants.DATE_TIME_FMT);
    }
}
@Pipe({
  name: 'timeStampFormat'
})
export class TimeStampFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value === null) return
    if (value !== undefined){
      let d=formatDate(value,"yyyy-MM-dd",'fr')
      return Date.parse(d);
    } else  return (value)
    
  }
}