import { Constants } from './constants';
import { Pipe, PipeTransform } from '@angular/core';
//import { DatePipe } from '@angular/common';

@Pipe({
  name: 'brReplace'
})

export class BrReplacePipe  implements PipeTransform {
  transform(value: any, args?: any): any {
 
   // let returned= value;
  
      return value.replace(/\n/,'<br/>');
   }
  }
