
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'frCurrency'
})
export class CurrencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value === null) return
    return value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR'});
  }
}
