
import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';

export const BACKEND_LAYOUT: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'prospects',
        loadChildren: () => import('../prospects/prospects.module').then(m => m.ProspectsModule),
        canLoad: [AuthGuard]

    },
    {
        path: 'client',
        loadChildren: () => import('../client/client.module').then(m => m.ClientModule),
        canLoad: [AuthGuard]

    },
    {
        path: 'quotes',
        loadChildren: () => import('../quotes/quotes.module').then(m => m.QuotesModule),
        canLoad: [AuthGuard]

    },
    {
        path: 'bo',
        loadChildren: () => import('../shared/shared.module').then(m => m.SharedModule),
        canLoad: [AuthGuard]

    },
    {
        path: 'tools',
        loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule),
        canLoad: [AuthGuard]
    },
    {
        path: '',
        loadChildren: () => import('../shared/shared.module').then(m => m.SharedModule),
        canLoad: [AuthGuard]

    },
]

